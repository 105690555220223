import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, CircularProgress, Menu, MenuItem, Snackbar, Alert } from '@mui/material';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { initiateDownload, checkDownloadStatus } from '../../services/projectApi';

const DownloadManager = ({ projectId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [, setStatusId] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const downloadedUrlsRef = useRef(new Set());
    const pollTimeoutRef = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showSnackbar = (message, severity = 'info') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const handleDownloadLinks = useCallback((links) => {
        links.forEach(url => {
            if (!downloadedUrlsRef.current.has(url)) {
                window.open(url, '_blank');
                downloadedUrlsRef.current.add(url);
            }
        });
    }, []);

    const pollDownloadStatus = useCallback(async (currentStatusId) => {
        if (!currentStatusId) return;

        try {
            const status = await checkDownloadStatus(projectId, currentStatusId);
            setStatusMessage(status.zipStatus);

            if (status.downloads.length > 0) {
                handleDownloadLinks(status.downloads);
            }

            if (status.zipStatus === 'ready') {
                setIsDownloading(false);
                showSnackbar('Download completed', 'success');
            } else {
                pollTimeoutRef.current = setTimeout(() => pollDownloadStatus(currentStatusId), 30000);
            }
        } catch (error) {
            console.error('Failed to check download status:', error);
            setIsDownloading(false);
            showSnackbar('Failed to get download status. Please try again.', 'error');
        }
    }, [projectId, handleDownloadLinks]);

    const startDownload = async (selectedOnly) => {
        handleClose();
        // Reset states for new download
        setIsDownloading(true);
        setStatusMessage('Downloading');
        setStatusId(null);
        downloadedUrlsRef.current.clear();

        if (pollTimeoutRef.current) {
            clearTimeout(pollTimeoutRef.current);
        }

        try {
            const response = await initiateDownload(projectId, selectedOnly);
            setStatusId(response.status_id);
            if (response.zip_status === 'ready' && response.downloads.length > 0) {
                // Immediate download available
                handleDownloadLinks(response.downloads);
                setIsDownloading(false);
                showSnackbar('Download completed', 'success');
            } else {
                // Start polling for status
                pollDownloadStatus(response.status_id);
            }
        } catch (error) {
            console.error('Failed to initiate download:', error);
            setIsDownloading(false);
            showSnackbar('Failed to start download. Please try again.', 'error');
        }
    };

    useEffect(() => {
        return () => {
            if (pollTimeoutRef.current) {
                clearTimeout(pollTimeoutRef.current);
            }
        };
    }, []);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                startIcon={isDownloading ? <CircularProgress size={24} color="inherit" /> : <CloudDownloadIcon />}
                onClick={handleClick}
                disabled={isDownloading}
            >
                {isDownloading ? statusMessage : 'Download'}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => startDownload(false)}>Download All</MenuItem>
                <MenuItem onClick={() => startDownload(true)}>Download Selected</MenuItem>
            </Menu>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default DownloadManager;