import React, { useState, useMemo } from 'react';
import { Box, Typography, Dialog, DialogContent, Grid } from '@mui/material';
import ImageCard from './ImageCard';
import OptimizedImage from './OptimizedImage';

const ImageGroup = ({ group, projectId, onSelectionChange, onImageClick }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [hoveredGroup, setHoveredGroup] = useState(false);

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => setIsDialogOpen(false);

    const stackedImages = useMemo(() => {
        const selectedImage = group.images.find(img => img.is_selected);
        if (selectedImage) {
            return [selectedImage, ...group.images.filter(img => !img.is_selected)].slice(0, 3);
        }
        return group.images.slice(0, 3);
    }, [group.images]);

    const isAnyImageSelected = group.images.some(img => img.is_selected);

    const handleImageSelection = (imageId, isSelected) => {
        onSelectionChange(imageId, isSelected);
    };

    const handleImageCardClick = (imageId) => {
        onImageClick(imageId);
        handleCloseDialog();
    };

    const renderStackedImages = () => (
        <Box
            onClick={handleOpenDialog}
            onMouseEnter={() => setHoveredGroup(true)}
            onMouseLeave={() => setHoveredGroup(false)}
            sx={{
                position: 'relative',
                width: '100%',
                paddingTop: '66.67%',
                cursor: 'pointer',
                borderRadius: 2,
                boxShadow: isAnyImageSelected
                    ? '0 0 0 4px rgba(25, 118, 210, 0.5), 0 4px 20px rgba(25, 118, 210, 0.5)'
                    : '0 4px 12px 0 rgba(0,0,0,0.2)',
                transition: 'all 0.3s ease-in-out',
                transform: isAnyImageSelected ? 'scale(1.02)' : 'scale(1)',
                '&:hover': {
                    boxShadow: isAnyImageSelected
                        ? '0 0 0 4px rgba(25, 118, 210, 0.5), 0 8px 25px rgba(25, 118, 210, 0.6)'
                        : '0 5px 20px 0 rgba(0,0,0,0.5)',
                },
            }}
        >
            {stackedImages.slice().reverse().map((image, index) => (
                <OptimizedImage
                    key={image.image_id}
                    thumbHash={image.thumb_hash}
                    lowResUrl={image.thumb_url}
                    alt={image.file_name}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: 2,
                        transition: 'all 0.3s ease-in-out',
                        transform: hoveredGroup
                            ? `rotate(${2 * (index - 1)}deg) translateY(${4 * (index - 1)}px)`
                            : 'none',
                        zIndex: stackedImages.length - index,
                    }}
                />
            ))}
            <Box
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    borderRadius: '50%',
                    width: 24,
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: stackedImages.length + 1,
                }}
            >
                <Typography variant="caption">{group.images.length}</Typography>
            </Box>
        </Box>
    );

    return (
        <>
            {renderStackedImages()}
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
                <DialogContent>
                    <Grid container spacing={2}>
                        {group.images.map((image) => (
                            <Grid item xs={12} sm={6} md={4} key={image.image_id}>
                                <ImageCard
                                    image={image}
                                    projectId={projectId}
                                    onSelectionChange={handleImageSelection}
                                    onImageClick={() => handleImageCardClick(image.image_id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ImageGroup;