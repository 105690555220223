import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { Fullscreen } from '@mui/icons-material';
import OptimizedImage from './OptimizedImage';

const SelectedImagesList = ({ selectedImages, onDragStart, imagePlacementMap, currentPageNumber }) => {
    const getImageStyle = (imageId) => {
        const placement = imagePlacementMap.get(imageId);
        if (!placement) return {};
        if (placement.includes(currentPageNumber)) {
            return { borderColor: 'primary.main', borderWidth: 2 };
        }
        return { borderColor: 'grey.500', borderWidth: 2 };
    };

    const getTooltipTitle = (imageId) => {
        const placement = imagePlacementMap.get(imageId);
        if (!placement) return "Not placed in album";
        if (placement.includes(currentPageNumber)) {
            return `Placed on current page (${currentPageNumber})`;
        }
        return `Placed on page(s): ${placement.join(', ')}`;
    };

    return (
        <Box
            sx={{
                width: 250,
                overflowY: 'auto',
                padding: 2,
                borderLeft: '1px solid #ccc',
            }}
        >
            <Typography variant="h6" gutterBottom>Selected Images</Typography>
            {selectedImages.map((image) => (
                <Tooltip title={getTooltipTitle(image.image_id)} key={image.image_id}>
                    <Box
                        draggable
                        onDragStart={(e) => onDragStart(e, image.image_id)}
                        sx={{
                            marginBottom: 2,
                            padding: 1,
                            border: '1px solid #ddd',
                            borderRadius: 1,
                            cursor: 'move',
                            position: 'relative',
                            ...getImageStyle(image.image_id),
                        }}
                    >
                        <Box sx={{ position: 'relative' }}>
                            <OptimizedImage
                                thumbHash={image.thumb_hash}
                                lowResUrl={image.thumb_url}
                                alt={image.file_name}
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    filter: image.is_full_page ? 'brightness(0.8)' : 'none',
                                }}
                            />
                            {image.is_full_page && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        color: 'white',
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <Fullscreen sx={{ fontSize: '2rem', marginRight: 1 }} />
                                    FULL PAGE
                                </Box>
                            )}
                        </Box>
                        <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>{image.file_name}</Typography>
                    </Box>
                </Tooltip>
            ))}
        </Box>
    );
};

export default SelectedImagesList;