import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, CircularProgress, Box, Typography, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import ImageCard from './ImageCard';
import ImageGroup from './ImageGroup';
import FileUploader from '../UploadManager/FileUploader';
import DownloadManager from './DownloadManager';
import FullScreenImageView from './FullScreenImageView';
import { fetchProjectImagesInGroups, updateImageSelection } from '../../services/projectApi';
import { PhotoLibrary as PhotoLibraryIcon } from '@mui/icons-material';

const ImageGallery = () => {
    const { projectId } = useParams();
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [nextCursorId, setNextCursorId] = useState(null);
    const [, setTotalCount] = useState(0);
    const [fullScreenView, setFullScreenView] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);

    const containerRef = useRef(null);
    const timerRef = useRef(null);

    const fetchImages = useCallback(async () => {
        if (loading || !hasMore) return;
        setLoading(true);
        try {
            const { groups, next_cursor_id, total_count } = await fetchProjectImagesInGroups(projectId, nextCursorId);
            const newImages = Object.entries(groups);

            setImages(prevImages => {
                const updatedImages = [...prevImages, ...newImages];
                const currentImageCount = updatedImages.reduce((sum, [_, group]) => sum + group.images.length, 0);
                setTotalCount(prevTotalCount => {
                    const currentTotalCount = Math.max(prevTotalCount, total_count);
                    const newHasMore = currentImageCount < currentTotalCount;
                    setHasMore(newHasMore);
                    setIsProcessing(newHasMore && newImages.length === 0);
                    return currentTotalCount;
                });
                if (next_cursor_id !== null) {
                    setNextCursorId(next_cursor_id);
                }
                return updatedImages;
            });
        } catch (err) {
            console.error("Error fetching images:", err);
            setError('Failed to fetch images. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [projectId, nextCursorId, loading, hasMore]);

    const handleNewUpload = useCallback(() => {
        if (!hasMore) {
            setHasMore(true);
            setIsProcessing(true);
        }
    }, [hasMore]);

    const handleSelectionChange = useCallback((imageId, isSelected) => {
        setImages(prevImages =>
            prevImages.map(([groupId, group]) => [
                groupId,
                {
                    ...group,
                    images: group.images.map(img =>
                        img.image_id === imageId ? { ...img, is_selected: isSelected } : img
                    )
                }
            ])
        );
    }, []);

    const handleImageClick = useCallback((imageId) => {
        const flatImages = images.flatMap(([_, group]) => group.images);
        const index = flatImages.findIndex(img => img.image_id === imageId);
        setCurrentImageIndex(index);
        setFullScreenView(true);
    }, [images]);

    const handleCloseFullScreenView = useCallback(() => {
        setFullScreenView(false);
    }, []);

    const handleNavigate = useCallback((direction) => {
        const flatImages = images.flatMap(([_, group]) => group.images);
        setCurrentImageIndex(prevIndex => {
            if (direction === 'next') {
                return (prevIndex + 1) % flatImages.length;
            } else {
                return (prevIndex - 1 + flatImages.length) % flatImages.length;
            }
        });
    }, [images]);

    const handleFullScreenSelect = useCallback(async (imageId, isSelected) => {
        try {
            await updateImageSelection(projectId, imageId, isSelected);
            handleSelectionChange(imageId, isSelected);
        } catch (error) {
            console.error('Failed to update image selection:', error);
        }
    }, [projectId, handleSelectionChange]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting && hasMore && !loading && !isProcessing) {
                fetchImages();
            }
        }, options);

        const currentContainerRef = containerRef.current;

        if (currentContainerRef) {
            observer.observe(currentContainerRef);
        }

        return () => {
            if (currentContainerRef) {
                observer.unobserve(currentContainerRef);
            }
        };
    }, [fetchImages, hasMore, loading, isProcessing]);

    useEffect(() => {
        if (isProcessing) {
            timerRef.current = setInterval(() => {
                fetchImages();
            }, 45 * 1000); // Poll every 45 seconds when processing
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [isProcessing, fetchImages]);

    // useEffect(() => {
    //     if (images.length === 0 && hasMore) {
    //         fetchImages();
    //     }
    // }, [images.length, hasMore, fetchImages]);

    const renderEmptyState = () => (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="50vh"
        >
            <PhotoLibraryIcon sx={{ fontSize: 100, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h5" color="text.secondary" gutterBottom>
                No images yet
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center" maxWidth="400px" mb={3}>
                Start by uploading some images to your project. They'll appear here once they're processed.
            </Typography>
        </Box>
    );

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Container maxWidth="xl" sx={{ height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', py: 3 }}>
            <Box display="flex" justifyContent="flex-end" mb={3} gap={3}>
                <DownloadManager projectId={projectId} />
                <FileUploader projectId={projectId} onUploadComplete={handleNewUpload} />
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
                {images.length === 0 && !loading && !isProcessing ? (
                    renderEmptyState()
                ) : (
                    <Grid container spacing={3}>
                        {images.map(([groupId, group]) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={groupId}>
                                {group.count > 1 ? (
                                    <ImageGroup
                                        group={group}
                                        projectId={projectId}
                                        onSelectionChange={handleSelectionChange}
                                        onImageClick={handleImageClick}
                                    />
                                ) : (
                                    <ImageCard
                                        image={group.images[0]}
                                        projectId={projectId}
                                        onSelectionChange={handleSelectionChange}
                                        onImageClick={handleImageClick}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                )}
                {(hasMore || isProcessing) && (
                    <Box ref={containerRef} display="flex" justifyContent="center" mt={2} height="100px">
                        {loading ? <CircularProgress /> :
                            <Typography>
                                {isProcessing ? "Processing images..." : "Scroll for more"}
                            </Typography>
                        }
                    </Box>
                )}
                {!hasMore && !isProcessing && images.length > 0 && (
                    <Typography align="center" mt={2}>
                        That's all we have!
                    </Typography>
                )}
            </Box>
            {fullScreenView && (
                <FullScreenImageView
                    images={images.flatMap(([_, group]) => group.images)}
                    currentIndex={currentImageIndex}
                    onClose={handleCloseFullScreenView}
                    onNavigate={handleNavigate}
                    onSelect={handleFullScreenSelect}
                    projectId={projectId}
                />
            )}
        </Container>
    );
};

export default ImageGallery;