import React, { useState, useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getLayoutsByCategory, getLayoutData, getCategoryName, getCategoryFromGridId } from '../utils/layoutUtils';

const GridTemplates = ({ onSelectGrid, selectedGridId }) => {
    const [expandedCategory, setExpandedCategory] = useState(null);
    const categories = getLayoutsByCategory();

    useEffect(() => {
        if (selectedGridId) {
            const category = getCategoryFromGridId(selectedGridId);
            setExpandedCategory(category.toString());
        }
    }, [selectedGridId]);

    const handleCategoryChange = (category) => (event, isExpanded) => {
        setExpandedCategory(isExpanded ? category : null);
    };

    const renderLayoutPreview = (grid_id) => {
        const layout = getLayoutData(grid_id);
        return (
            <Box sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
            }}>
                {layout.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            position: 'absolute',
                            left: `${item.x1 * 100}%`,
                            top: `${item.y1 * 100}%`,
                            width: `${(item.x2 - item.x1) * 100}%`,
                            height: `${(item.y2 - item.y1) * 100}%`,
                            border: '1px solid #ddd',
                            boxSizing: 'border-box',
                        }}
                    />
                ))}
            </Box>
        );
    };

    return (
        <Box sx={{ p: 2, maxHeight: '100%', overflowY: 'auto' }}>
            {Object.keys(categories).map((category) => (
                <Accordion
                    key={category}
                    expanded={expandedCategory === category}
                    onChange={handleCategoryChange(category)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{getCategoryName(category)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            {categories[category].map((grid_id) => (
                                <Grid item xs={4} sm={4} md={4} key={grid_id}>
                                    <Paper
                                        elevation={selectedGridId === grid_id ? 8 : 1}
                                        onClick={() => onSelectGrid(grid_id)}
                                        sx={{
                                            cursor: 'pointer',
                                            border: selectedGridId === grid_id ? '2px solid #1976d2' : '1px solid #ccc',
                                            '&:hover': { boxShadow: 3 },
                                            aspectRatio: '1',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 1,
                                        }}
                                    >
                                        <Box sx={{ flex: 1, position: 'relative' }}>
                                            {renderLayoutPreview(grid_id)}
                                        </Box>
                                        <Typography variant="caption" sx={{ mt: 1, fontSize: '0.7rem', textAlign: 'center' }}>
                                            {`${grid_id}`}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default GridTemplates;