import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, List, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton, Typography, Snackbar, Alert, CircularProgress, Box } from '@mui/material';
import { Send as SendIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { shareProject, removeProjectShare, listProjectShares } from '../../services/projectApi';

const ShareDialog = ({ open, onClose, project }) => {
    const [email, setEmail] = useState('');
    const [shares, setShares] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    const showSnackbar = (message, severity = 'error') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const fetchShares = useCallback(async () => {
        if (project) {
            setIsLoading(true);
            try {
                const fetchedShares = await listProjectShares(project.project_id);
                setShares(fetchedShares);
            } catch (error) {
                console.error('Error fetching shares:', error.response.data);
                showSnackbar(`${error.response.data.detail}`);
            } finally {
                setIsLoading(false);
            }
        }
    }, [project]);

    useEffect(() => {
        if (open && project) {
            // Reset state when opening dialog for a new project
            setShares([]);
            setEmail('');
            setIsLoading(true);
            fetchShares();
        }
    }, [open, project, fetchShares]);

    const handleShare = async () => {
        if (email && project) {
            setIsLoading(true);
            try {
                const newShare = await shareProject(project.project_id, email);
                setShares(prevShares => [...prevShares, newShare]);
                setEmail('');
                showSnackbar('Project shared successfully', 'success');
            } catch (error) {
                console.error('Error sharing shares:', error.response.data);
                showSnackbar(`${error.response.data.detail}`);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleRemoveShare = async (shareEmail) => {
        if (project) {
            setIsLoading(true);
            try {
                await removeProjectShare(project.project_id, shareEmail);
                setShares(prevShares => prevShares.filter(share => share.email !== shareEmail));
                showSnackbar('Share removed successfully', 'success');
            } catch (error) {
                console.error('Error removing shares:', error.response.data);
                showSnackbar(`${error.response.data.detail}`);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Share "{project?.project_name}"</DialogTitle>
                <DialogContent>
                    <Typography>Share your project with others.</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email address to share"
                        disabled={isLoading}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        onClick={handleShare}
                        fullWidth
                        disabled={isLoading}
                    >
                        Share
                    </Button>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" my={4}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <List>
                            {shares.map((share) => (
                                <ListItem
                                    key={share.email}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleRemoveShare(share.email)}
                                            disabled={isLoading}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>{share.email[0].toUpperCase()}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={share.email}
                                        secondary={`Access: ${share.permission}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ShareDialog;