import React, { useState, useRef } from 'react';
import { Box, Button, IconButton, Paper, Snackbar, Alert } from '@mui/material';
import { Reorder } from "framer-motion";
import { Add as AddIcon, Delete as DeleteIcon, DragIndicator } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { createOrUpdateAlbumPage, deleteAlbumPage, updateAlbumOrder } from '../../services/projectApi';
import ConfirmationDialog from './ConfirmationDialog';
import { getLayoutData } from '../utils/layoutUtils';
import OptimizedImage from './OptimizedImage';

const AlbumList = ({ pages, setPages, selectedImages }) => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [pageToDelete, setPageToDelete] = useState(null);
    const [localPages, setLocalPages] = useState(pages);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const originalOrderRef = useRef(pages.map(page => page.page_id));
    const isReorderingRef = useRef(false);

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const handleReorder = (newOrder) => {
        setLocalPages(newOrder);
        isReorderingRef.current = true;
    };

    const handlePointerUp = async () => {
        if (!isReorderingRef.current) return;

        const newOrder = localPages.map(page => page.page_id);
        if (JSON.stringify(newOrder) !== JSON.stringify(originalOrderRef.current)) {
            try {
                const pagesOrder = localPages.map((page, index) => ({
                    page_id: page.page_id,
                    page_number: index + 1
                }));
                await updateAlbumOrder(projectId, { pages_order: pagesOrder });
                originalOrderRef.current = newOrder;

                const updatedPages = localPages.map((page, index) => ({
                    ...page,
                    page_number: index + 1
                }));
                setPages(updatedPages);
                setLocalPages(updatedPages);
                showSnackbar('Album pages updated successfully');
            } catch (error) {
                console.error('Error updating page order:', error);
                showSnackbar('Failed to update album pages', 'error');
                setLocalPages(pages);
            }
        }
        isReorderingRef.current = false;
    };

    const handleAddPage = async () => {
        const newPageNumber = localPages.length + 1;
        const newPage = {
            page_number: newPageNumber,
            grid_id: 1001,
            image_props: []
        };

        try {
            const result = await createOrUpdateAlbumPage(projectId, newPage);
            const updatedPage = { ...newPage, page_id: result.page_id };
            const updatedPages = [...localPages, updatedPage];
            setLocalPages(updatedPages);
            setPages(updatedPages);
            showSnackbar('New page added successfully');
        } catch (error) {
            console.error('Error adding new page:', error);
            showSnackbar('Failed to add new page', 'error');
        }
    };

    const handleDeletePage = (page) => {
        setPageToDelete(page);
        setShowDeleteConfirmation(true);
    };

    const confirmDeletePage = async () => {
        if (pageToDelete) {
            try {
                await deleteAlbumPage(projectId, pageToDelete.page_number);
                const updatedPages = localPages.filter(p => p.page_id !== pageToDelete.page_id)
                    .map((page, index) => ({ ...page, page_number: index + 1 }));
                setLocalPages(updatedPages);
                setPages(updatedPages);

                const pagesOrder = updatedPages.map((page, index) => ({
                    page_id: page.page_id,
                    page_number: index + 1
                }));
                await updateAlbumOrder(projectId, { pages_order: pagesOrder });
                showSnackbar('Page deleted successfully');
            } catch (error) {
                console.error('Error deleting page:', error);
                showSnackbar('Failed to delete page', 'error');
            }
        }
        setShowDeleteConfirmation(false);
        setPageToDelete(null);
    };

    const navigateToEditPage = (pageNumber) => {
        navigate(`/project/album/${projectId}/edit/${pageNumber}`);
    };

    const PageItem = ({ page }) => {
        const layout = getLayoutData(page.grid_id);

        return (
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    mb: 2,
                    width: '100%',
                    maxWidth: "500px",
                    '&:hover .delete-button': { opacity: 1 },
                    transition: 'opacity 0.2s, box-shadow 0.3s',
                    '&:hover': { boxShadow: 6 },
                }}
            >
                <Box
                    sx={{
                        width: 100,
                        height: 75,
                        border: '1px solid #ccc',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: 2,
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {layout.map((cell, index) => {
                        const imageProp = page.image_props.find(prop => prop.position === index);
                        const image = imageProp ? selectedImages.find(img => img.image_id === imageProp.image_id) : null;

                        return (
                            <Box
                                key={index}
                                sx={{
                                    position: 'absolute',
                                    left: `${cell.x1 * 100}%`,
                                    top: `${cell.y1 * 100}%`,
                                    width: `${(cell.x2 - cell.x1) * 100}%`,
                                    height: `${(cell.y2 - cell.y1) * 100}%`,
                                    border: '1px solid #ddd',
                                    overflow: 'hidden',
                                }}
                            >
                                {image && (
                                    <OptimizedImage
                                        thumbHash={image.thumb_hash}
                                        lowResUrl={image.thumb_url}
                                        alt={image.file_name}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flex: 1,
                        cursor: 'pointer',
                    }}
                    onClick={() => navigateToEditPage(page.page_number)}
                >
                    <Box sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Page {page.page_number}</Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            className="delete-button"
                            sx={{ opacity: 0, transition: 'opacity 0.2s' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeletePage(page);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        <DragIndicator sx={{ cursor: 'grab' }} />
                    </Box>
                </Box>
            </Paper>
        );
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            p: 3
        }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                maxWidth: '500px',
            }}>
                <Button startIcon={<AddIcon />} onClick={handleAddPage}>Add Page</Button>
            </Box>
            <Box sx={{
                width: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                flexGrow: 1,
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(0,0,0,0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                },
                justifyContent: 'center',
                display: 'flex',
            }}>
                <Reorder.Group
                    axis="y"
                    values={localPages}
                    onReorder={handleReorder}
                    onPointerUp={handlePointerUp}
                    style={{
                        width: '100%',
                        maxWidth: '500px',
                        padding: 0,
                    }}
                >
                    {localPages.map((page) => (
                        <Reorder.Item key={page.page_id} value={page} style={{ listStyle: 'none', width: '100%' }}>
                            <PageItem page={page} />
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            </Box>
            <ConfirmationDialog
                open={showDeleteConfirmation}
                onConfirm={confirmDeletePage}
                onCancel={() => setShowDeleteConfirmation(false)}
                title="Delete Page"
                content="Are you sure you want to delete this page? This action cannot be undone."
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AlbumList;