import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box, Button, Grid, Card, CardContent, IconButton, Menu, MenuItem, Paper, Divider, TextField, Popover, CircularProgress, Snackbar, Alert } from '@mui/material';
import { Add as AddIcon, MoreVert as MoreVertIcon, Folder as FolderIcon, FolderOff as FolderOffIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import ShareDialog from './ShareDialog';
import { listProjects, createProject, renameProject, deleteProject, archiveProject } from '../../services/projectApi';
import useNavigationWarning from '../../hooks/useNavigationWarnings';

const Projects = () => {
    useNavigationWarning();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [newProjectName, setNewProjectName] = useState('');
    const [renameProjectName, setRenameProjectName] = useState('');
    const [actionLoading, setActionLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [newProjectAnchorEl, setNewProjectAnchorEl] = useState(null);
    const [renameAnchorEl, setRenameAnchorEl] = useState(null);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const navigate = useNavigate();

    const showSnackbar = useCallback((message, severity = 'info') => {
        setSnackbar({ open: true, message, severity });
    }, []);

    const fetchProjects = useCallback(async () => {
        try {
            setLoading(true);
            const fetchedProjects = await listProjects();
            setProjects(fetchedProjects);
        } catch (err) {
            console.error('Error fetching projects:', err);
            setError('Failed to load projects. Please try again.');
            showSnackbar('Failed to load projects. Please try again.', 'error');
        } finally {
            setLoading(false);
        }
    }, [showSnackbar]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const handleProjectAction = async (action, projectId = null, newName = null) => {
        try {
            setActionLoading(true);
            let updatedProject;
            switch (action) {
                case 'create':
                    updatedProject = await createProject(newName);
                    setProjects([...projects, updatedProject]);
                    break;
                case 'rename':
                    updatedProject = await renameProject(projectId, newName);
                    setProjects(projects.map(p => p.project_id === updatedProject.project_id ? updatedProject : p));
                    break;
                case 'delete':
                    await deleteProject(projectId);
                    setProjects(projects.filter(p => p.project_id !== projectId));
                    break;
                case 'archive':
                    await archiveProject(projectId);
                    setProjects(projects.map(p => p.project_id === projectId ? { ...p, is_archived: true } : p));
                    break;
                default:
                    throw new Error('Invalid action');
            }
            showSnackbar(`Project ${action}d successfully`);
        } catch (err) {
            console.error(`Error ${action}ing project:`, err);
            showSnackbar(`Failed to ${action} project. Please try again.`, 'error');
        } finally {
            setActionLoading(false);
        }
    };

    const handleMenuOpen = (event, project) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedProject(project);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleNewProjectClose = () => {
        setNewProjectAnchorEl(null);
        setNewProjectName('');
    };

    const handleRenameClose = () => {
        setRenameAnchorEl(null);
        setRenameProjectName('');
    };

    const handleRenameClick = () => {
        setRenameProjectName(selectedProject?.project_name || '');
        setRenameAnchorEl(menuAnchorEl);
        handleMenuClose();
    };

    const handleShareClick = () => {
        setShareDialogOpen(true);
        handleMenuClose();
    };

    const handleProjectClick = (projectId) => {
        navigate(`/project/image_gallery/${projectId}`);
    };

    const renderProjectCard = (project) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={project.project_id}>
            <Card
                variant="outlined"
                sx={{
                    '&:hover': { boxShadow: 1 },
                    borderRadius: 2,
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    opacity: project.is_archived ? 0.5 : 1,
                }}
                onClick={() => handleProjectClick(project.project_id)}
            >
                <CardContent sx={{
                    p: '0 !important',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', px: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FolderIcon sx={{ mr: 1, color: 'text.secondary' }} />
                            <Typography variant="body1">{project.project_name}</Typography>
                        </Box>
                        <IconButton
                            size="small"
                            onClick={(e) => handleMenuOpen(e, project)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );

    const renderProjects = () => {
        if (projects.length === 0) {
            return (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="50vh">
                    <FolderOffIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
                    <Typography variant="h6" color="text.secondary">
                        No projects available
                    </Typography>
                </Box>
            );
        }
        return (
            <Grid container spacing={2}>
                {projects.map(renderProjectCard)}
            </Grid>
        );
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h4">Projects</Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={(e) => setNewProjectAnchorEl(e.currentTarget)}
                        >
                            NEW PROJECT
                        </Button>
                    </Box>
                    {renderProjects()}
                </Paper>

                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleRenameClick}>Rename</MenuItem>
                    <MenuItem onClick={() => {
                        handleProjectAction('archive', selectedProject?.project_id);
                        handleMenuClose();
                    }}>Archive</MenuItem>
                    <MenuItem onClick={handleShareClick}>Share</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {
                        handleProjectAction('delete', selectedProject?.project_id);
                        handleMenuClose();
                    }} sx={{ color: 'error.main' }}>Delete</MenuItem>
                </Menu>

                <Popover
                    open={Boolean(newProjectAnchorEl)}
                    anchorEl={newProjectAnchorEl}
                    onClose={handleNewProjectClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="New Project Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newProjectName}
                            onChange={(e) => setNewProjectName(e.target.value)}
                        />
                        <Button
                            onClick={() => {
                                handleProjectAction('create', null, newProjectName);
                                handleNewProjectClose();
                            }}
                            sx={{ ml: 1 }}
                            disabled={actionLoading}
                        >
                            {actionLoading ? <CircularProgress size={24} /> : 'Create'}
                        </Button>
                    </Box>
                </Popover>

                <Popover
                    open={Boolean(renameAnchorEl)}
                    anchorEl={renameAnchorEl}
                    onClose={handleRenameClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="New Project Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={renameProjectName}
                            onChange={(e) => setRenameProjectName(e.target.value)}
                        />
                        <Button
                            onClick={() => {
                                handleProjectAction('rename', selectedProject?.project_id, renameProjectName);
                                handleRenameClose();
                            }}
                            sx={{ ml: 1 }}
                            disabled={actionLoading}
                        >
                            {actionLoading ? <CircularProgress size={24} /> : 'Rename'}
                        </Button>
                    </Box>
                </Popover>

                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>

                <ShareDialog
                    open={shareDialogOpen}
                    onClose={() => setShareDialogOpen(false)}
                    project={selectedProject}
                />
            </Box>
        </Box>
    );
};

export default Projects;