import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box, CircularProgress, Snackbar, Alert, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import ImageGallery from './ImageGallery';
import Album from './Album';
import Arrange from './Arrange';
import { validateProjectAccess } from '../../services/projectApi';
import useNavigationWarning from '../../hooks/useNavigationWarnings';
import { useUpload } from '../../context/UploadContext';

const ProjectView = () => {
    useNavigationWarning();
    const { uploading } = useUpload();
    const { projectId, view } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    const showSnackbar = useCallback((message, severity = 'info') => {
        setSnackbar({ open: true, message, severity });
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    const fetchProjectAccess = useCallback(async () => {
        if (loading) {
            try {
                const projectData = await validateProjectAccess(projectId);
                setProject(projectData);
            } catch (err) {
                console.error('Error validating project access:', err);
                showSnackbar('Not found or You don\'t have access to this project', 'error');
                if (!uploading) {
                    setTimeout(() => {
                        navigate('/projects');
                    }, 3000);
                }
            } finally {
                setLoading(false);
            }
        }
    }, [projectId, navigate, showSnackbar, uploading, loading]);

    useEffect(() => {
        fetchProjectAccess();
    }, [fetchProjectAccess]);

    const renderContent = useCallback(() => {
        if (!project) return null;
        switch (view) {
            case 'image_gallery':
                return <ImageGallery projectId={projectId} />;
            case 'arrange':
                return <Arrange projectId={projectId} />;
            case 'album':
                return <Album projectId={projectId} />;
            default:
                return <Typography>Not Found</Typography>;
        }
    }, [project, view, projectId]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!project) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar projectId={projectId} currentView={view} />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h4" gutterBottom>{project?.project_name}</Typography>
                    </Box>
                    {renderContent()}
                </Paper>
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProjectView;