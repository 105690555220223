import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const AuthRedirect = ({ children }) => {
    const { user } = useAuth();

    if (user) {
        return <Navigate to="/projects" replace />;
    }

    return children;
};

export default AuthRedirect;