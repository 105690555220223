import React, { createContext, useState, useContext, useEffect } from 'react';
import { signIn, signUp, signOut, getSession, onAuthStateChange } from '../services/auth';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Check active sessions and sets the user
        const session = getSession();
        setUser(session?.user ?? null);
        setLoading(false);

        // Listen for changes on auth state (logged in, signed out, etc.)
        const { data: listener } = onAuthStateChange(async (event, session) => {
            setUser(session?.user ?? null);
            setLoading(false);
        });

        return () => {
            listener?.unsubscribe();
        };
    }, []);

    // Login with email and password
    const login = async (email, password) => {
        try {
            setLoading(true);
            const user = await signIn(email, password);
            return user;
        } catch (error) {
            console.error('Error logging in:', error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    // Logout
    const logout = async () => {
        try {
            setLoading(true);
            await signOut();
        } catch (error) {
            console.error('Error logging out:', error.message);
        } finally {
            setLoading(false);
        }
    };

    // Sign up
    const register = async (email, password) => {
        try {
            setLoading(true);
            const user = await signUp(email, password);
            return user;
        } catch (error) {
            console.error('Error signing up:', error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, register, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);