import axios from 'axios';
import { getSession } from './auth';

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = async () => {
    const session = await getSession();
    return {
        Authorization: `Bearer ${session?.access_token}`
    };
};

export const createProject = async (projectName) => {
    const headers = await getAuthHeader();
    const response = await axios.post(`${API_URL}/projects`, { project_name: projectName }, { headers });
    return response.data;
};

export const renameProject = async (projectId, newName) => {
    const headers = await getAuthHeader();
    const response = await axios.put(`${API_URL}/projects/${projectId}`, { project_name: newName }, { headers });
    return response.data;
};

export const deleteProject = async (projectId) => {
    const headers = await getAuthHeader();
    const response = await axios.delete(`${API_URL}/projects/${projectId}`, { headers });
    return response.data;
};

export const archiveProject = async (projectId) => {
    const headers = await getAuthHeader();
    const response = await axios.post(`${API_URL}/projects/${projectId}/archive`, {}, { headers });
    return response.data;
};

export const listProjects = async () => {
    const headers = await getAuthHeader();
    console.log(headers)
    const response = await axios.get(`${API_URL}/projects`, { headers });
    return response.data;
};

export const shareProject = async (projectId, email) => {
    const headers = await getAuthHeader();
    const response = await axios.post(`${API_URL}/projects/${projectId}/share`, { email }, { headers });
    return response.data;
};

export const removeProjectShare = async (projectId, email) => {
    const headers = await getAuthHeader();
    const response = await axios.delete(`${API_URL}/projects/${projectId}/share/${email}`, { headers });
    return response.data;
};

export const listProjectShares = async (projectId) => {
    const headers = await getAuthHeader();
    const response = await axios.get(`${API_URL}/projects/${projectId}/shares`, { headers });
    return response.data;
};

export const validateProjectAccess = async (projectId) => {
    const headers = await getAuthHeader();
    const response = await axios.get(`${API_URL}/projects/${projectId}/validate`, { headers });
    return response.data;
};

export const getUploadUrl = async (projectId) => {
    const headers = await getAuthHeader();
    const response = await axios.post(`${API_URL}/upload/get-url`, { project_id: projectId }, { headers });
    return response.data;
};

export const fetchProjectImagesInGroups = async (projectId, cursorId = null, limit = 20) => {
    const headers = await getAuthHeader();
    const response = await axios.get(`${API_URL}/projects/${projectId}/images/groups`, {
        params: { cursor_id: cursorId, limit },
        headers
    });
    return response.data;
};

export const updateImageSelection = async (projectId, imageId, isSelected) => {
    const headers = await getAuthHeader();
    const response = await axios.patch(`${API_URL}/projects/${projectId}/images/${imageId}/select/${isSelected}`, {}, { headers });
    return response.data;
};

export const notifyImageUpload = async (projectId, fileNames) => {
    const headers = await getAuthHeader();
    const response = await axios.post(
        `${API_URL}/projects/${projectId}/images/notify`,
        { file_names: fileNames },
        { headers }
    );
    return response.data;
};

export const getSelectedImages = async (projectId) => {
    const headers = await getAuthHeader();
    const response = await axios.get(`${API_URL}/projects/${projectId}/images/selected`, { headers });
    return response.data;
};

export const updateImageFullPageStatus = async (projectId, imageId, isFullPage) => {
    const headers = await getAuthHeader();
    const response = await axios.patch(`${API_URL}/projects/${projectId}/images/${imageId}/full-page/${isFullPage}`, {}, { headers });
    return response.data;
};

export const updateImageOrder = async (projectId, imageOrder) => {
    const headers = await getAuthHeader();
    const response = await axios.post(`${API_URL}/projects/${projectId}/images/reorder`, { image_order: imageOrder }, { headers });
    return response.data;
};

export const createOrUpdateAlbumPage = async (projectId, pageData) => {
    const headers = await getAuthHeader();
    const response = await axios.post(`${API_URL}/projects/${projectId}/album/pages`, pageData, { headers });
    return response.data;
};

export const getAlbumPages = async (projectId, pageNumber = null) => {
    const headers = await getAuthHeader();
    const params = pageNumber ? { page_number: pageNumber } : {};
    const response = await axios.get(`${API_URL}/projects/${projectId}/album/pages`, { headers, params });
    return response.data;
};

export const deleteAlbumPage = async (projectId, pageNumber) => {
    const headers = await getAuthHeader();
    const response = await axios.delete(`${API_URL}/projects/${projectId}/album/pages/${pageNumber}`, { headers });
    return response.data;
};

export const updateAlbumOrder = async (projectId, orderData) => {
    const headers = await getAuthHeader();
    const response = await axios.post(`${API_URL}/projects/${projectId}/album/reorder`, orderData, { headers });
    return response.data;
};

export const initiateDownload = async (projectId, selected = false) => {
    const headers = await getAuthHeader();
    const response = await axios.get(`${API_URL}/projects/${projectId}/images/zip`, {
        params: { selected },
        headers
    });
    return response.data;
};

export const checkDownloadStatus = async (projectId, statusId) => {
    const headers = await getAuthHeader();
    const response = await axios.get(`${API_URL}/projects/${projectId}/images/zip/${statusId}`, { headers });
    return {
        zipStatus: response.data.zip_status,
        downloads: response.data.downloads,
        statusId: response.data.status_id
    };
};

export const downloadImage = async (projectId, imageId) => {
    const headers = await getAuthHeader();
    const response = await axios.get(`${API_URL}/projects/${projectId}/images/${imageId}/download`, { headers });
    return response.data;
};