import React, { createContext, useContext, useState, useCallback, useRef } from 'react';

const UploadContext = createContext(null);

export const UploadProvider = ({ children }) => {
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [statusMessage, setStatusMessage] = useState({ text: '', severity: 'info' });
    const cancelUploadRef = useRef(false);
    const uploadUrlRef = useRef({ url: '', expiry: null });
    const activeUploadsRef = useRef([]);

    const updateUploadState = useCallback((newState) => {
        setUploading(prev => newState.uploading !== undefined ? newState.uploading : prev);
        setUploadProgress(prev => newState.uploadProgress !== undefined ? newState.uploadProgress : prev);
        setFiles(prev => newState.files !== undefined ? newState.files : prev);
        setExpanded(prev => newState.expanded !== undefined ? newState.expanded : prev);
        setStatusMessage(prev => newState.statusMessage !== undefined ? newState.statusMessage : prev);
    }, []);

    const updateFileStatus = useCallback((index, newStatus) => {
        setFiles(prevFiles => prevFiles.map((file, i) =>
            i === index ? { ...file, status: newStatus } : file
        ));
    }, []);

    const resetUploadState = useCallback(() => {
        setUploading(false);
        setUploadProgress(0);
        setFiles([]);
        setExpanded(false);
        setStatusMessage({ text: '', severity: 'info' });
        cancelUploadRef.current = false;
        uploadUrlRef.current = { url: '', expiry: null };
        activeUploadsRef.current = [];
    }, []);

    return (
        <UploadContext.Provider value={{
            uploading,
            uploadProgress,
            files,
            expanded,
            statusMessage,
            cancelUploadRef,
            uploadUrlRef,
            activeUploadsRef,
            updateUploadState,
            resetUploadState,
            updateFileStatus,
        }}>
            {children}
        </UploadContext.Provider>
    );
};

export const useUpload = () => {
    const context = useContext(UploadContext);
    if (context === null) {
        throw new Error('useUpload must be used within an UploadProvider');
    }
    return context;
};