import React, { useState, useEffect, useRef, memo } from 'react';
import { thumbHashToDataURL } from 'thumbhash';
import { Box } from '@mui/material';

const OptimizedImage = memo(({ thumbHash, lowResUrl, alt, onClick, sx = {} }) => {
    const [imageSrc, setImageSrc] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const imageRef = useRef(null);

    useEffect(() => {
        if (thumbHash) {
            const thumbHashData = Uint8Array.from(atob(thumbHash), c => c.charCodeAt(0));
            const dataURL = thumbHashToDataURL(thumbHashData);
            setImageSrc(dataURL);
        }
    }, [thumbHash]);

    useEffect(() => {
        if (lowResUrl) {
            const img = new Image();
            img.onload = () => {
                setImageSrc(lowResUrl);
                setIsLoaded(true);
            };
            img.src = lowResUrl;
        }
    }, [lowResUrl]);

    return (
        <Box
            component="img"
            ref={imageRef}
            src={imageSrc}
            alt={alt}
            onClick={onClick}
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'opacity 0.3s ease-in-out',
                opacity: isLoaded ? 1 : 0.5,
                ...sx,
            }}
        />
    );
});

export default OptimizedImage;