import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from './context/AuthContext';
import { UploadProvider } from './context/UploadContext';
import PrivateRoute from './components/PrivateRoute';
import Authentication from './components/Authentication/Authentication';
import AuthRedirect from './components/Authentication/AuthRedirect';
import Projects from './components/Projects/Projects';
import ProjectView from './components/ProjectView/ProjectView';
import UploadProgressIndicator from './components/UploadManager/UploadProgressIndicator';
import useNavigationWarning from './hooks/useNavigationWarnings';

const theme = createTheme();

function AppContent() {
    useNavigationWarning();

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<AuthRedirect><Authentication /></AuthRedirect>} />
                <Route
                    path="/projects"
                    element={
                        <PrivateRoute>
                            <Projects />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/project/:view/:projectId/*"
                    element={
                        <PrivateRoute>
                            <ProjectView />
                        </PrivateRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <UploadProgressIndicator />
        </div>
    );
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <UploadProvider>
                    <Router>
                        <AppContent />
                    </Router>
                </UploadProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;