import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Button, IconButton, Tooltip, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Image as ImageIcon, PhotoAlbum as AlbumIcon, ExitToApp as ExitToAppIcon, Reorder as ReorderIcon, ChevronLeft, ChevronRight, ArrowBack as ProjectsIcon } from '@mui/icons-material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useUpload } from '../../context/UploadContext';

const drawerWidth = 240;
const miniDrawerWidth = 65;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: miniDrawerWidth,
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: open ? drawerWidth : miniDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const sidebarItems = [
    { name: 'Projects', icon: <ProjectsIcon />, path: '/projects' },
    { name: 'Gallery', icon: <ImageIcon />, path: 'image_gallery' },
    { name: 'Arrange', icon: <ReorderIcon />, path: 'arrange' },
    { name: 'Album', icon: <AlbumIcon />, path: 'album' },
];

const SideBar = ({ projectId }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();
    const { uploading, resetUploadState } = useUpload();

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleSignOut = async () => {
        if (uploading) {
            // Show confirmation dialog (implement this part)
        } else {
            await performSignOut();
        }
    };

    const performSignOut = async () => {
        try {
            await logout();
            resetUploadState();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <StyledDrawer variant="permanent" open={open}>
            <DrawerHeader>
                <Typography variant="h6" noWrap sx={{ flexGrow: 1, ml: 2 }}>
                    {open ? 'Nit - Pic' : 'NP'}
                </Typography>
                <IconButton onClick={handleDrawerToggle}>
                    {open ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {sidebarItems.map((item) => {
                    const isActive = location.pathname === item.path ||
                        (item.path !== '/projects' && location.pathname.includes(item.path));
                    return (
                        <ListItem
                            button
                            key={item.name}
                            component={Link}
                            to={item.path === '/projects' ? item.path : `/project/${item.path}/${projectId}`}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                bgcolor: isActive ? 'action.selected' : 'inherit',
                            }}
                        >
                            <Tooltip title={open ? '' : item.name} placement="right">
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItem>
                    );
                })}
            </List>
            <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <Tooltip title={open ? '' : 'Sign Out'} placement="right">
                    <Button
                        fullWidth
                        startIcon={<ExitToAppIcon />}
                        onClick={handleSignOut}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        {open && 'Sign Out'}
                    </Button>
                </Tooltip>
            </Box>
        </StyledDrawer>
    );
};

export default SideBar;