import React from 'react';
import { Box, Typography, LinearProgress, Accordion, AccordionSummary, AccordionDetails, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Fade } from '@mui/material';
import { Cancel as CancelIcon, InsertDriveFile as FileIcon, Image as ImageIcon, Movie as VideoIcon, AudioFile as AudioIcon, PictureAsPdf as PdfIcon, CheckCircle as SuccessIcon, Error as ErrorIcon, Autorenew as PendingIcon, ExpandMore as ExpandMoreIcon, Close as CloseIcon, CloudSync as UploadingIcon, HistoryToggleOff as TimeoutIcon } from '@mui/icons-material';
// import { Refresh as RetryIcon } from '@mui/icons-material';
import { useUpload } from '../../context/UploadContext';

const UploadProgressIndicator = () => {
    const {
        uploading,
        uploadProgress,
        files,
        expanded,
        statusMessage,
        updateUploadState,
        cancelUploadRef,
        activeUploadsRef,
    } = useUpload();

    const handleAccordionChange = (event, isExpanded) => {
        updateUploadState({ expanded: isExpanded });
    };

    const handleCancelUpload = () => {
        cancelUploadRef.current = true;
        activeUploadsRef.current.forEach(controller => controller.abort());
        updateUploadState({
            statusMessage: { text: 'Upload cancelled', severity: 'warning' },
            files: files.map(f => f.status === 'pending' ? { ...f, status: 'cancelled' } : f)
        });
    };

    const handleCloseAccordion = () => {
        if (!uploading) {
            updateUploadState({
                expanded: false,
                files: [],
                uploadProgress: 0,
                statusMessage: { text: '', severity: 'info' }
            });
        }
    };

    // const handleRetryUpload = (fileToRetry) => {
    //     if (retryUpload) {
    //         retryUpload(fileToRetry);
    //     }
    // };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <ImageIcon />;
            case 'mp4':
            case 'avi':
            case 'mov':
                return <VideoIcon />;
            case 'mp3':
            case 'wav':
                return <AudioIcon />;
            case 'pdf':
                return <PdfIcon />;
            default:
                return <FileIcon />;
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'success':
                return <SuccessIcon color="success" />;
            case 'uploading':
                return <UploadingIcon color="action" />;
            case 'error':
                return <ErrorIcon color="error" />;
            case 'pending':
                return <PendingIcon color="action" />;
            case 'cancelled':
                return <CancelIcon color="action" />;
            case 'timeout':
                return <TimeoutIcon color="warning" />;
            default:
                return <PendingIcon color="action" />;
        }
    };

    if (files.length === 0) {
        return null;
    }

    return (
        <Box
            position="fixed"
            bottom={16}
            right={16}
            width={300}
            maxHeight={400}
            zIndex={9999}
        >
            <Accordion expanded={expanded} onChange={handleAccordionChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="upload-progress-content"
                    id="upload-progress-header"
                >
                    <Box width="100%" mr={2}>
                        <Typography variant="body2">
                            {uploading ? `Uploading... ${Math.round(uploadProgress)}%` : 'Upload Complete'}
                        </Typography>
                        <LinearProgress variant="determinate" value={uploadProgress} sx={{ mt: 1 }} />
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Paper elevation={0} sx={{ p: 1 }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                            <Typography variant="body2">Files</Typography>
                            {uploading && (
                                <IconButton size="small" onClick={handleCancelUpload}>
                                    <CancelIcon />
                                </IconButton>
                            )}
                            {!uploading && (
                                <IconButton size="small" onClick={handleCloseAccordion}>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Fade in={statusMessage.text !== ''}>
                            <Typography
                                variant="body2"
                                color={statusMessage.severity === 'success' ? 'success.main' :
                                    statusMessage.severity === 'error' ? 'error.main' :
                                        'warning.main'}
                                sx={{ mb: 1 }}
                            >
                                {statusMessage.text}
                            </Typography>
                        </Fade>
                        <List dense sx={{ maxHeight: 200, overflowY: 'auto' }}>
                            {files.map((file, index) => (
                                <ListItem key={index} sx={{ py: 0 }}>
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        {getFileIcon(file.file.name)}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.file.name}
                                        secondary={`${(file.file.size / 1024 / 1024).toFixed(2)} MB`}
                                        primaryTypographyProps={{ variant: 'body2', noWrap: true }}
                                        secondaryTypographyProps={{ variant: 'caption' }}
                                    />
                                    <ListItemIcon sx={{ minWidth: 36 }}>
                                        {getStatusIcon(file.status)}
                                    </ListItemIcon>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default UploadProgressIndicator;