import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Button, IconButton, Drawer, Snackbar, Alert } from '@mui/material';
import { ChevronLeft, ChevronRight, GridView, Replay as Undo } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { createOrUpdateAlbumPage } from '../../services/projectApi';
import AlbumPage from './AlbumPage';
import GridTemplates from './GridTemplates';
import ConfirmationDialog from './ConfirmationDialog';
import SelectedImagesList from './SelectedImagesList';

const MAX_HISTORY_LENGTH = 20;
const DRAWER_WIDTH = 600;

const AlbumEdit = ({ pages, setPages, selectedImages, setSelectedImages, imagePlacementMap }) => {
    const { projectId, pageNumber } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(null);
    const [isGridDrawerOpen, setIsGridDrawerOpen] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [navigationAction, setNavigationAction] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [showLayoutConfirmDialog, setShowLayoutConfirmDialog] = useState(false);
    const [pendingLayoutChange, setPendingLayoutChange] = useState(null);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);

    useEffect(() => {
        const page = pages.find(p => p.page_number === Number(pageNumber));
        if (page) {
            setCurrentPage(page);
            setHistory([page]);
            setHistoryIndex(0);
        } else {
            navigate(`/project/album/${projectId}`);
        }
    }, [pageNumber, pages, projectId, navigate]);

    useEffect(() => {
        if (currentPage) {
            const placedImageIds = currentPage.image_props.map(prop => prop.image_id);
            setSelectedImages(prevImages =>
                prevImages.map(img => ({
                    ...img,
                    isPlaced: placedImageIds.includes(img.image_id)
                }))
            );
        }
    }, [currentPage, setSelectedImages]);

    const showSnackbar = useCallback((message, severity = 'info') => {
        setSnackbar({ open: true, message, severity });
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar({ ...snackbar, open: false });
    };

    const handlePageChange = (newPageNumber) => {
        if (hasUnsavedChanges) {
            setShowConfirmDialog(true);
            setNavigationAction(() => () => {
                navigate(`/project/album/${projectId}/edit/${newPageNumber}`);
            });
        } else {
            navigate(`/project/album/${projectId}/edit/${newPageNumber}`);
        }
    };

    const handleSavePage = async () => {
        try {
            await createOrUpdateAlbumPage(projectId, currentPage);
            setPages(prevPages => prevPages.map(p => p.page_number === currentPage.page_number ? currentPage : p));
            setHasUnsavedChanges(false);
            setHistory([currentPage]);
            setHistoryIndex(0);
            showSnackbar('Page saved successfully', 'success');
        } catch (error) {
            console.error('Error saving page:', error);
            showSnackbar('Failed to save page. Please try again.', 'error');
        }
    };

    const handleConfirmNavigation = async () => {
        await handleSavePage();
        setShowConfirmDialog(false);
        if (navigationAction) {
            navigationAction();
            setNavigationAction(null);
        }
    };

    const handleCancelNavigation = () => {
        setShowConfirmDialog(false);
        setNavigationAction(null);
    };

    const handleDragStart = (e, imageId) => {
        e.dataTransfer.setData('text/plain', imageId);
    };

    const handleUpdatePage = useCallback((updatedPageData) => {
        setCurrentPage(updatedPageData);
        setHasUnsavedChanges(true);
        setHistory(prev => {
            const newHistory = [...prev.slice(0, historyIndex + 1), updatedPageData];
            if (newHistory.length > MAX_HISTORY_LENGTH) {
                newHistory.shift();
            }
            return newHistory;
        });
        setHistoryIndex(prev => Math.min(prev + 1, MAX_HISTORY_LENGTH - 1));
    }, [historyIndex]);

    const handleChangeLayout = (newGridId) => {
        if (currentPage.image_props.length > 0) {
            setPendingLayoutChange(newGridId);
            setShowLayoutConfirmDialog(true);
        } else {
            applyLayoutChange(newGridId);
        }
    };

    const applyLayoutChange = (newGridId) => {
        const updatedPage = { ...currentPage, grid_id: newGridId, image_props: [] };
        handleUpdatePage(updatedPage);
        setIsGridDrawerOpen(false);
    };

    const confirmLayoutChange = () => {
        if (pendingLayoutChange) {
            const clearedImageIds = currentPage.image_props.map(prop => prop.image_id);
            setSelectedImages(prevImages =>
                prevImages.map(img =>
                    clearedImageIds.includes(img.image_id) ? { ...img, isPlaced: false } : img
                )
            );
            applyLayoutChange(pendingLayoutChange);
        }
        setShowLayoutConfirmDialog(false);
        setPendingLayoutChange(null);
    };

    const cancelLayoutChange = () => {
        setShowLayoutConfirmDialog(false);
        setPendingLayoutChange(null);
    };

    const handleUndo = useCallback(() => {
        if (historyIndex > 0) {
            setHistoryIndex(prev => prev - 1);
            setCurrentPage(history[historyIndex - 1]);
            setHasUnsavedChanges(true);
        }
    }, [history, historyIndex]);

    const canUndo = useMemo(() => historyIndex > 0, [historyIndex]);

    if (!currentPage) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <Box>
                    <IconButton onClick={() => handlePageChange(Number(pageNumber) - 1)} disabled={Number(pageNumber) === 1}>
                        <ChevronLeft />
                    </IconButton>
                    <Typography variant="body1" component="span">Page {pageNumber}</Typography>
                    <IconButton onClick={() => handlePageChange(Number(pageNumber) + 1)} disabled={Number(pageNumber) === pages.length}>
                        <ChevronRight />
                    </IconButton>
                </Box>
                <Box>
                    <Button startIcon={<GridView />} onClick={() => setIsGridDrawerOpen(true)}>Change Layout</Button>
                    <Button
                        startIcon={<Undo />}
                        onClick={handleUndo}
                        disabled={!canUndo}
                        sx={{ mr: 1 }}
                    >
                        Undo
                    </Button>
                    <Button variant="contained" onClick={handleSavePage} disabled={!hasUnsavedChanges}>Save Page</Button>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', pr: 2 }}>
                    <AlbumPage
                        pageData={currentPage}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                        setHasUnsavedChanges={setHasUnsavedChanges}
                        onUpdatePage={handleUpdatePage}
                    />
                </Box>
                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                    <SelectedImagesList
                        selectedImages={selectedImages}
                        onDragStart={handleDragStart}
                        imagePlacementMap={imagePlacementMap}
                        currentPageNumber={Number(pageNumber)}
                    />
                </Box>
            </Box>

            <Drawer
                anchor="left"
                open={isGridDrawerOpen}
                onClose={() => setIsGridDrawerOpen(false)}
                sx={{
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                        minWidth: '300px',
                        maxWidth: '80vw',
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }}>
                    <Typography variant="h6">Choose Layout</Typography>
                    <IconButton onClick={() => setIsGridDrawerOpen(false)}>
                        <ChevronLeft />
                    </IconButton>
                </Box>
                <GridTemplates onSelectGrid={handleChangeLayout} selectedGridId={currentPage.grid_id} />
            </Drawer>

            <ConfirmationDialog
                open={showConfirmDialog}
                onConfirm={handleConfirmNavigation}
                onCancel={handleCancelNavigation}
                title="Unsaved Changes"
                content="You have unsaved changes. Do you want to save before proceeding?"
            />

            <ConfirmationDialog
                open={showLayoutConfirmDialog}
                onConfirm={confirmLayoutChange}
                onCancel={cancelLayoutChange}
                title="Change Layout"
                content="Changing the layout will clear all images from the current page. Are you sure you want to proceed?"
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AlbumEdit;