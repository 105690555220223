import { useEffect, useCallback } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { useUpload } from '../context/UploadContext';

const useNavigationWarning = () => {
    const { uploading, resetUploadState } = useUpload();
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const handleBeforeUnload = useCallback((event) => {
        if (uploading) {
            event.preventDefault();
            event.returnValue = 'Upload in progress. Are you sure you want to leave?';
        }
    }, [uploading]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [handleBeforeUnload]);

    useEffect(() => {
        if (uploading && navigationType !== 'POP') {
            const unblock = navigate((nextLocation) => {
                if (window.confirm('Upload in progress. Are you sure you want to leave? The upload will be cancelled.')) {
                    resetUploadState();
                    return true;
                }
                return false;
            });

            return () => {
                if (unblock) unblock();
            };
        }
    }, [navigate, uploading, resetUploadState, navigationType]);

    return null;
};

export default useNavigationWarning;