import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Chip, Button } from '@mui/material';
import { Close, ChevronLeft, ChevronRight, CheckCircle, Download } from '@mui/icons-material';
import OptimizedImage from './OptimizedImage';
import { downloadImage } from '../../services/projectApi';

const getChipColor = (tag) => {
    const lowerTag = tag.toLowerCase();
    if (['very_sharp', 'properly_exposed'].includes(lowerTag)) return 'success';
    if (['moderate_sharp', 'moderate_blur', 'slightly_under_exposed', 'slightly_over_exposed'].includes(lowerTag)) return 'info';
    if (['very_blur', 'under_exposed', 'over_exposed', 'eyes_closed', 'no_faces'].includes(lowerTag)) return 'error';
    return 'default';
};

const formatTag = (tag) => {
    return tag.toLowerCase().replace(/_/g, ' ');
};

const FullScreenImageView = ({ images, currentIndex, onClose, onNavigate, onSelect, projectId }) => {
    const [currentImage, setCurrentImage] = useState(images[currentIndex]);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        setCurrentImage(images[currentIndex]);
    }, [currentIndex, images]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowLeft':
                    onNavigate('prev');
                    break;
                case 'ArrowRight':
                    onNavigate('next');
                    break;
                case 'Enter':
                    if (onSelect) {
                        onSelect(currentImage.image_id, !currentImage.is_selected);
                    }
                    break;
                case 'Escape':
                    onClose();
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onNavigate, onSelect, onClose, currentImage]);

    const handleSelect = () => {
        if (onSelect) {
            onSelect(currentImage.image_id, !currentImage.is_selected);
        }
    };

    const handleDownload = async () => {
        try {
            setIsDownloading(true);
            const response = await downloadImage(projectId, currentImage.image_id);
            window.open(response.url, '_blank');
        } catch (error) {
            console.error('Error downloading image:', error);
            // You might want to show an error message to the user here
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9999,
            }}
        >
            <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', gap: 2, zIndex: 10000 }}>
                <IconButton
                    onClick={handleDownload}
                    disabled={isDownloading}
                    sx={{
                        color: 'white',
                        padding: '12px',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                >
                    <Download />
                </IconButton>
                <IconButton
                    onClick={onClose}
                    sx={{
                        color: 'white',
                        padding: '12px',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            <Box sx={{ position: 'relative', width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <OptimizedImage
                    thumbHash={currentImage.thumb_hash}
                    lowResUrl={currentImage.low_res_url}
                    alt={currentImage.file_name}
                    sx={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                    }}
                />
                <IconButton
                    onClick={() => onNavigate('prev')}
                    sx={{
                        position: 'absolute', left: 16, top: '50%', color: 'white', zIndex: 10000,
                        padding: '12px',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={() => onNavigate('next')}
                    sx={{
                        position: 'absolute', right: 16, top: '50%', color: 'white', zIndex: 10000,
                        padding: '12px',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    }}
                >
                    <ChevronRight />
                </IconButton>
            </Box>
            <Box sx={{ mt: 2, color: 'white', textAlign: 'center' }}>
                <Typography variant="h6">{currentImage.file_name}</Typography>
                {currentImage.tags && (
                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5, justifyContent: 'center' }}>
                        {currentImage.tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={formatTag(tag)}
                                size="small"
                                color={getChipColor(tag)}
                                sx={{ m: 0.5 }}
                            />
                        ))}
                    </Box>
                )}
                {onSelect && (
                    <Button
                        variant="contained"
                        color={currentImage.is_selected ? "success" : "primary"}
                        startIcon={<CheckCircle />}
                        onClick={handleSelect}
                        sx={{ mt: 2 }}
                    >
                        {currentImage.is_selected ? 'Selected' : 'Select'}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default FullScreenImageView;