import React, { useState, useEffect } from 'react';
import { Box, Typography, Checkbox, Chip, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import { updateImageSelection, downloadImage } from '../../services/projectApi';
import OptimizedImage from './OptimizedImage';

const getChipColor = (tag) => {
    const lowerTag = tag.toLowerCase();
    if (['very_sharp', 'properly_exposed'].includes(lowerTag)) return 'success';
    if (['moderate_sharp', 'moderate_blur', 'slightly_under_exposed', 'slightly_over_exposed'].includes(lowerTag)) return 'info';
    if (['very_blur', 'under_exposed', 'over_exposed', 'eyes_closed', 'no_faces'].includes(lowerTag)) return 'error';
    return 'default';
};

const formatTag = (tag) => {
    return tag.toLowerCase().replace(/_/g, ' ');
};

const ImageCard = ({ image, projectId, onSelectionChange, onImageClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isSelected, setIsSelected] = useState(image.is_selected);

    useEffect(() => {
        setIsSelected(image.is_selected);
    }, [image.is_selected]);

    const handleSelect = async (event) => {
        event.stopPropagation();
        const newSelectedState = !isSelected;

        // Optimistically update UI
        setIsSelected(newSelectedState);
        if (onSelectionChange) {
            onSelectionChange(image.image_id, newSelectedState);
        }

        try {
            await updateImageSelection(projectId, image.image_id, newSelectedState);
        } catch (error) {
            console.error('Failed to update image selection:', error);
            // Revert UI if API call fails
            setIsSelected(!newSelectedState);
            if (onSelectionChange) {
                onSelectionChange(image.image_id, !newSelectedState);
            }
        }
    };

    const handleImageClick = (event) => {
        event.stopPropagation();
        onImageClick(image.image_id);
    }

    const handleDownload = async (event) => {
        event.stopPropagation();
        try {
            const response = await downloadImage(projectId, image.image_id);
            window.open(response.url, '_blank');
        } catch (error) {
            console.error('Failed to download image:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                position: 'relative',
                paddingTop: '66.67%', // 3:2 aspect ratio for a wider look
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: isSelected
                    ? '0 0 0 4px rgba(25, 118, 210, 0.5), 0 4px 20px rgba(25, 118, 210, 0.5)'
                    : '0 4px 12px 0 rgba(0,0,0,0.2)',
                transition: 'all 0.3s ease-in-out',
                transform: isSelected ? 'scale(1.02)' : 'scale(1)',
                '&:hover': {
                    boxShadow: isSelected
                        ? '0 0 0 4px rgba(25, 118, 210, 0.5), 0 8px 25px rgba(25, 118, 210, 0.6)'
                        : '0 5px 20px 0 rgba(0,0,0,0.5)',
                },
            }}
        >
            <OptimizedImage
                thumbHash={image.thumb_hash}
                lowResUrl={image.thumb_url}
                alt={image.file_name}
                onClick={handleImageClick}
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                    padding: '8px',
                    transform: 'translateY(100%)',
                    transition: 'transform 0.3s ease-in-out',
                    '.MuiBox-root:hover > &': {
                        transform: 'translateY(0)',
                    },
                }}
            >
                <Typography variant="subtitle1" noWrap>
                    {image.file_name}
                </Typography>
                <Box sx={{ mt: 0.5, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {image.tags.map((tag, index) => (
                        <Chip
                            key={index}
                            label={formatTag(tag)}
                            size="small"
                            color={getChipColor(tag)}
                            sx={{ fontSize: '0.7rem', height: '20px' }}
                        />
                    ))}
                </Box>
            </Box>
            {(isHovered || isSelected) && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        display: 'flex',
                        gap: 1,
                    }}
                >
                    <IconButton
                        onClick={handleDownload}
                        sx={{
                            color: 'rgba(255, 255, 255, 0.9)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                        }}
                    >
                        <DownloadIcon />
                    </IconButton>
                    <Checkbox
                        icon={<CheckCircleIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={isSelected}
                        onChange={handleSelect}
                        sx={{
                            color: 'rgba(255, 255, 255, 0.9)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            '&.Mui-checked': {
                                color: 'primary.main',
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ImageCard;