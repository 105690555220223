import React, { useCallback, useRef } from 'react';
import { Button } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { getUploadUrl, notifyImageUpload } from '../../services/projectApi';
import { useUpload } from '../../context/UploadContext';

const UPLOAD_TIMEOUT = 60 * 1000; // 60 seconds
const MAX_RETRIES = 3;
const PARALLEL_UPLOADS = 5;

const FileUploader = ({ projectId, onUploadComplete }) => {
    const {
        uploading,
        updateUploadState,
        cancelUploadRef,
        activeUploadsRef,
        updateFileStatus,
    } = useUpload();

    const fileInputRef = useRef(null);
    const uploadUrlRef = useRef({ url: '', expiry: null });

    const showStatusMessage = useCallback((text, severity = 'info') => {
        updateUploadState({ statusMessage: { text, severity } });
    }, [updateUploadState]);

    const getValidUploadUrl = useCallback(async () => {
        if (!uploadUrlRef.current.url || new Date() >= new Date(uploadUrlRef.current.expiry)) {
            try {
                const { upload_url, expiry } = await getUploadUrl(projectId);
                uploadUrlRef.current = { url: upload_url, expiry };
            } catch (error) {
                console.error('Failed to get upload URL:', error);
                throw new Error('Failed to get upload URL. Please try again.');
            }
        }
        return uploadUrlRef.current.url;
    }, [projectId]);

    const uploadFileWithTimeout = useCallback(async (file, initialUploadUrl, fileIndex) => {
        let currentUploadUrl = initialUploadUrl;

        const performUpload = () => {
            return new Promise((resolve, reject) => {
                const controller = new AbortController();
                activeUploadsRef.current.push(controller);

                const timeoutId = setTimeout(() => {
                    controller.abort();
                    reject(new Error('Upload timeout'));
                }, UPLOAD_TIMEOUT);

                fetch(currentUploadUrl, {
                    method: 'PUT',
                    body: file,
                    signal: controller.signal,
                    headers: { 'Content-Type': file.type },
                })
                    .then(response => {
                        clearTimeout(timeoutId);
                        if (!response.ok) {
                            throw new Error(`Upload failed: ${response.statusText}`);
                        }
                        resolve(true);
                    })
                    .catch(error => {
                        clearTimeout(timeoutId);
                        reject(error);
                    })
                    .finally(() => {
                        activeUploadsRef.current = activeUploadsRef.current.filter(c => c !== controller);
                    });
            });
        };

        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        for (let retryCount = 0; retryCount <= MAX_RETRIES; retryCount++) {
            try {
                const result = await performUpload();
                updateFileStatus(fileIndex, 'success');
                return result;
            } catch (error) {
                console.error(error);

                if (cancelUploadRef.current) {
                    updateFileStatus(fileIndex, 'cancelled');
                    throw new Error('Upload cancelled');
                }

                if (error.name === 'AbortError' || error.message === 'Upload timeout') {
                    updateFileStatus(fileIndex, 'timeout');
                    // showStatusMessage(`Upload timeout for ${file.name}`, 'warning');
                } else if (error.message.includes('Unauthorized') || error.message.includes('Not Found')) {
                    try {
                        const newUploadUrl = await getValidUploadUrl();
                        currentUploadUrl = `${newUploadUrl}${file.name}`;
                        continue; // Skip the delay and retry immediately with the new URL
                    } catch (urlError) {
                        console.error('Failed to get new upload URL:', urlError);
                        updateFileStatus(fileIndex, 'error');
                        showStatusMessage(`Failed to get new upload URL for ${file.name}`, 'error');
                        throw urlError;
                    }
                } else {
                    updateFileStatus(fileIndex, 'error');
                    showStatusMessage(`Error uploading ${file.name}: ${error.message}`, 'error');
                }

                if (retryCount < MAX_RETRIES) {
                    await delay(1000 * (retryCount + 1));
                } else {
                    throw error;
                }
            }
        }
        throw new Error('Max retries exceeded');
    }, [activeUploadsRef, updateFileStatus, showStatusMessage, cancelUploadRef, getValidUploadUrl]);

    const uploadFileBatch = useCallback(async (files, startIndex) => {
        const uploadPromises = files.map(async (file, index) => {
            const fileIndex = startIndex + index;
            updateFileStatus(fileIndex, 'uploading');
            try {
                const uploadUrl = await getValidUploadUrl();
                await uploadFileWithTimeout(file, `${uploadUrl}${file.name}`, fileIndex);
                return file.name; // Return the file name if upload is successful
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
                updateFileStatus(fileIndex, 'error');
                return null; // Return null if upload failed
            }
        });

        const results = await Promise.all(uploadPromises);
        const successfulUploads = results.filter(Boolean); // Filter out null values

        // Notify backend about successful uploads
        if (successfulUploads.length > 0) {
            try {
                await notifyImageUpload(projectId, successfulUploads);
            } catch (error) {
                console.error('Error notifying backend about uploads:', error);
                showStatusMessage('Error notifying backend about uploads', 'error');
                // Consider how to handle this error
            }
        }

        return successfulUploads.length;
    }, [showStatusMessage, getValidUploadUrl, uploadFileWithTimeout, updateFileStatus, projectId]);

    const handleFilesSelected = useCallback(async (selectedFiles) => {
        if (!selectedFiles.length) return;

        updateUploadState({
            uploading: true,
            uploadProgress: 0,
            files: Array.from(selectedFiles).map(file => ({ file, status: 'pending' })),
        });

        let uploadedCount = 0;
        const totalFiles = selectedFiles.length;

        for (let i = 0; i < totalFiles; i += PARALLEL_UPLOADS) {
            if (cancelUploadRef.current) break;

            try {
                await getValidUploadUrl();
            } catch (error) {
                showStatusMessage(`Failed to get upload URL: ${error.message}`, 'error');
                return 0;
            }

            const batch = Array.from(selectedFiles).slice(i, i + PARALLEL_UPLOADS);
            const successCount = await uploadFileBatch(batch, i);
            uploadedCount += successCount;

            const progress = (uploadedCount / totalFiles) * 100;
            updateUploadState({ uploadProgress: progress });
        }

        updateUploadState({ uploading: false });
        if (onUploadComplete) onUploadComplete();

        if (cancelUploadRef.current) {
            showStatusMessage('Upload process cancelled', 'warning');
        } else if (uploadedCount === totalFiles) {
            showStatusMessage('All files uploaded successfully', 'success');
        } else if (uploadedCount > 0) {
            showStatusMessage(`Uploaded ${uploadedCount} out of ${totalFiles} files successfully`, 'warning');
        } else {
            showStatusMessage('Failed to upload files', 'error');
        }
    }, [updateUploadState, uploadFileBatch, cancelUploadRef, onUploadComplete, showStatusMessage, getValidUploadUrl]);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div>
            <input
                type="file"
                multiple
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => handleFilesSelected(e.target.files)}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                onClick={handleButtonClick}
                disabled={uploading}
            >
                Upload Files
            </Button>
        </div>
    );
};

export default FileUploader;