import React from 'react';
import { Box, Typography, IconButton, Tooltip, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Clear, Crop, FitScreen } from '@mui/icons-material';
import OptimizedImage from './OptimizedImage';
import { getLayoutData } from '../utils/layoutUtils';

const AlbumPage = ({ pageData, selectedImages, setSelectedImages, setHasUnsavedChanges, onUpdatePage }) => {
    const layout = getLayoutData(pageData.grid_id);

    const onDrop = (event, position) => {
        event.preventDefault();
        const imageId = event.dataTransfer.getData('text/plain');
        const draggedImage = selectedImages.find(img => img.image_id === imageId);

        // Check if the cell already has an image
        const existingImage = pageData.image_props.find(prop => prop.position === position);
        if (existingImage) {
            return; // Do nothing if there's already an image in this position
        }

        if (draggedImage) {
            const updatedImageProps = [
                ...pageData.image_props.filter(prop => prop.image_id !== imageId),
                { image_id: imageId, position, fit_mode: 0 } // 0 for 'fill'
            ];
            const updatedPageData = { ...pageData, image_props: updatedImageProps };
            onUpdatePage(updatedPageData);

            const updatedSelectedImages = selectedImages.map(img =>
                img.image_id === imageId ? { ...img, isPlaced: true } : img
            );
            setSelectedImages(updatedSelectedImages);
            setHasUnsavedChanges(true);
        }
    };

    const removeImage = (position) => {
        const removedImage = pageData.image_props.find(prop => prop.position === position);
        const updatedImageProps = pageData.image_props.filter(prop => prop.position !== position);
        const updatedPageData = { ...pageData, image_props: updatedImageProps };
        onUpdatePage(updatedPageData);

        const updatedSelectedImages = selectedImages.map(img =>
            img.image_id === removedImage.image_id ? { ...img, isPlaced: false } : img
        );
        setSelectedImages(updatedSelectedImages);
        setHasUnsavedChanges(true);
    };

    const toggleFitMode = (position) => {
        const updatedImageProps = pageData.image_props.map(prop =>
            prop.position === position ? { ...prop, fit_mode: prop.fit_mode === 0 ? 1 : 0 } : prop
        );
        const updatedPageData = { ...pageData, image_props: updatedImageProps };
        onUpdatePage(updatedPageData);
        setHasUnsavedChanges(true);
    };

    return (
        <Box
            sx={{
                flex: 1,
                border: '1px solid #ccc',
                position: 'relative',
                aspectRatio: '4 / 3',
                maxHeight: '100%',
                width: '100%',
            }}
        >
            {layout.map((cell, index) => {
                const imageProp = pageData.image_props.find(prop => prop.position === index);
                const image = imageProp ? selectedImages.find(img => img.image_id === imageProp.image_id) : null;

                return (
                    <Box
                        key={index}
                        sx={{
                            position: 'absolute',
                            left: `${cell.x1 * 100}%`,
                            top: `${cell.y1 * 100}%`,
                            width: `${(cell.x2 - cell.x1) * 100}%`,
                            height: `${(cell.y2 - cell.y1) * 100}%`,
                            border: '2px dashed #aaa',
                            boxSizing: 'border-box',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#f5f5f5',
                        }}
                        onDragOver={(e) => {
                            if (!image) {
                                e.preventDefault();
                            }
                        }}
                        onDrop={(e) => onDrop(e, index)}
                    >
                        {image ? (
                            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                <OptimizedImage
                                    thumbHash={image.thumb_hash}
                                    lowResUrl={image.low_res_url}
                                    alt={image.file_name}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: imageProp.fit_mode === 0 ? 'cover' : 'contain'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        opacity: 0,
                                        transition: 'opacity 0.3s',
                                        '&:hover': {
                                            opacity: 1,
                                        },
                                    }}
                                >
                                    <ToggleButtonGroup
                                        value={imageProp.fit_mode}
                                        exclusive
                                        onChange={() => toggleFitMode(index)}
                                        size="small"
                                    >
                                        <ToggleButton value={1} aria-label="fit" sx={{ color: 'white' }}>
                                            <Tooltip title="Fit">
                                                <FitScreen />
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value={0} aria-label="fill" sx={{ color: 'white' }}>
                                            <Tooltip title="Fill">
                                                <Crop />
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <IconButton
                                        onClick={() => removeImage(index)}
                                        sx={{ color: 'white', ml: 1 }}
                                    >
                                        <Clear />
                                    </IconButton>
                                </Box>
                            </Box>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                Drop image here
                            </Typography>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default AlbumPage;