import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Folder as FolderIcon, Storage as StorageIcon, CreditCard as CreditCardIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useUpload } from '../../context/UploadContext';

const drawerWidth = 260;

const SideBar = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { uploading, resetUploadState } = useUpload();
    const [openDialog, setOpenDialog] = useState(false);

    const handleSignOut = async () => {
        if (uploading) {
            setOpenDialog(true);
        } else {
            await performSignOut();
        }
    };

    const performSignOut = async () => {
        try {
            await logout();
            resetUploadState();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmSignOut = async () => {
        setOpenDialog(false);
        await performSignOut();
    };

    return (
        <>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Typography variant="h4" sx={{ p: 2, pl: 7 }}>Nit - Pic</Typography>
                <List>
                    {[
                        { text: 'Projects', icon: <FolderIcon />, path: '/projects' },
                        { text: 'Storage', icon: <StorageIcon />, path: '/storage' },
                        { text: 'Billing', icon: <CreditCardIcon />, path: '/billing' },
                    ].map((item) => (
                        <ListItem button key={item.text} onClick={() => navigate(item.path)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    <Button
                        fullWidth
                        startIcon={<ExitToAppIcon />}
                        onClick={handleSignOut}
                        sx={{
                            borderRadius: 0,
                            py: 1,
                            color: 'primary.main',
                            borderColor: 'divider',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        SIGN OUT
                    </Button>
                </Box>
            </Drawer>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Upload in Progress"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have an upload in progress. Signing out will cancel the upload. Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmSignOut} color="primary" autoFocus>
                        Sign Out
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default SideBar;