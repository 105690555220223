import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import SignIn from './SignIn';
import SignUp from './SignUp';
import useNavigationWarning from '../../hooks/useNavigationWarnings';

const Authentication = () => {
    useNavigationWarning();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 400, margin: 'auto', mt: 4 }}>
            <Typography variant="h3" component="h1" align="center" gutterBottom>
                Nit-Pic
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="auth tabs">
                    <Tab label="Sign In" />
                    <Tab label="Sign Up" />
                </Tabs>
            </Box>
            <Box sx={{ p: 3 }}>
                {value === 0 && <SignIn />}
                {value === 1 && <SignUp />}
            </Box>
        </Box>
    );
};

export default Authentication;
