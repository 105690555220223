import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Box, CircularProgress, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import { Reorder } from "framer-motion";
import { useParams } from 'react-router-dom';
import { DragIndicator, Fullscreen, FullscreenExit, PhotoLibrary as PhotoLibraryIcon } from '@mui/icons-material';
import { getSelectedImages, updateImageOrder, updateImageFullPageStatus } from '../../services/projectApi';
import OptimizedImage from './OptimizedImage';
import FullScreenImageView from './FullScreenImageView';

const Arrange = () => {
    const { projectId } = useParams();
    const [selectedImages, setSelectedImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fullScreenView, setFullScreenView] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const originalOrderRef = useRef([]);
    const isReorderingRef = useRef(false);

    const fetchSelectedImages = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getSelectedImages(projectId);
            setSelectedImages(response.images);
            originalOrderRef.current = response.images.map(img => img.image_id);
        } catch (err) {
            console.error('Error fetching selected images:', err);
            setError('Failed to load selected images. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [projectId]);

    useEffect(() => {
        fetchSelectedImages();
    }, [fetchSelectedImages]);

    const handleReorder = useCallback((newOrder) => {
        setSelectedImages(newOrder);
        isReorderingRef.current = true;
    }, []);

    const handlePointerUp = useCallback(async () => {
        if (!isReorderingRef.current) return;

        const newOrder = selectedImages.map(img => img.image_id);
        if (JSON.stringify(newOrder) !== JSON.stringify(originalOrderRef.current)) {
            try {
                const imageOrder = selectedImages.map((image, index) => ({
                    image_id: image.image_id,
                    order: index + 1
                }));
                await updateImageOrder(projectId, imageOrder);
                originalOrderRef.current = newOrder;
                setSnackbar({
                    open: true,
                    message: `Images order updated successfully`,
                    severity: 'success'
                });
            } catch (err) {
                console.error('Error updating image order:', err);
                setSnackbar({
                    open: true,
                    message: `Failed to update image order. Please try again.`,
                    severity: 'error'
                });
            }
        }
        isReorderingRef.current = false;
    }, [projectId, selectedImages]);

    const handleImageClick = useCallback((imageId) => {
        if (!isReorderingRef.current) {
            const index = selectedImages.findIndex(img => img.image_id === imageId);
            setCurrentImageIndex(index);
            setFullScreenView(true);
        }
    }, [selectedImages]);

    const handleCloseFullScreenView = useCallback(() => {
        setFullScreenView(false);
    }, []);

    const handleNavigate = useCallback((direction) => {
        setCurrentImageIndex(prevIndex => {
            if (direction === 'next') {
                return (prevIndex + 1) % selectedImages.length;
            } else {
                return (prevIndex - 1 + selectedImages.length) % selectedImages.length;
            }
        });
    }, [selectedImages.length]);

    const handleToggleFullPage = useCallback(async (imageId) => {
        try {
            const imageToUpdate = selectedImages.find(img => img.image_id === imageId);
            const newFullPageStatus = !imageToUpdate.is_full_page;
            await updateImageFullPageStatus(projectId, imageId, newFullPageStatus);
            setSelectedImages(prevImages =>
                prevImages.map(img =>
                    img.image_id === imageId ? { ...img, is_full_page: newFullPageStatus } : img
                )
            );
            setSnackbar({
                open: true,
                message: `Image set to ${newFullPageStatus ? 'full page' : 'normal'} successfully`,
                severity: 'success'
            });
        } catch (error) {
            console.error('Error updating full page status:', error);
            setSnackbar({
                open: true,
                message: 'Failed to update full page status. Please try again.',
                severity: 'error'
            });
        }
    }, [projectId, selectedImages]);

    const handleSnackbarClose = useCallback((event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar(prev => ({ ...prev, open: false }));
    }, []);

    const renderEmptyState = () => (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="50vh"
        >
            <PhotoLibraryIcon sx={{ fontSize: 100, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h5" color="text.secondary" gutterBottom>
                No selected images
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center" maxWidth="400px">
                Start by selecting images in the Gallery. Once you've chosen your favorites, they'll appear here for you to arrange.
            </Typography>
        </Box>
    );

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            p: 3
        }}>
            <Box sx={{
                width: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                flexGrow: 1,
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(0,0,0,0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                },
                justifyContent: 'center',
                display: 'flex',
            }}>
                {selectedImages.length === 0 ? (
                    renderEmptyState()
                ) : (
                    <Reorder.Group
                        axis="y"
                        values={selectedImages}
                        onReorder={handleReorder}
                        onPointerUp={handlePointerUp}
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                            padding: 0,
                        }}
                    >
                        {selectedImages.map((image) => (
                            <Reorder.Item key={image.image_id} value={image} style={{ listStyle: 'none', width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        p: 2,
                                        mb: 2,
                                        width: '100%',
                                        maxWidth: "500px",
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        boxShadow: 1,
                                        '&:hover': { boxShadow: 3 },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 100,
                                            height: 75,
                                            mr: 2,
                                            overflow: 'hidden',
                                            position: 'relative',
                                        }}
                                    >
                                        <OptimizedImage
                                            thumbHash={image.thumb_hash}
                                            lowResUrl={image.thumb_url}
                                            alt={image.file_name}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleImageClick(image.image_id)}
                                    >
                                        <Typography>{image.file_name}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip title={image.is_full_page ? "Set to normal page" : "Set to full page"}>
                                                <IconButton onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleToggleFullPage(image.image_id);
                                                }}>
                                                    {image.is_full_page ? <FullscreenExit /> : <Fullscreen />}
                                                </IconButton>
                                            </Tooltip>
                                            <DragIndicator sx={{ cursor: 'grab' }} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Reorder.Item>
                        ))}
                    </Reorder.Group>
                )}
            </Box>
            {fullScreenView && (
                <FullScreenImageView
                    projectId={projectId}
                    images={selectedImages}
                    currentIndex={currentImageIndex}
                    onClose={handleCloseFullScreenView}
                    onNavigate={handleNavigate}
                />
            )}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Arrange;