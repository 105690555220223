import layout1 from '../../data/layouts/layout_1.json';
import layout2 from '../../data/layouts/layout_2.json';
import layout3 from '../../data/layouts/layout_3.json';
import layout4 from '../../data/layouts/layout_4.json';
import layout5 from '../../data/layouts/layout_5.json';
import layout6 from '../../data/layouts/layout_6.json';
import layout7 from '../../data/layouts/layout_7.json';
import layout8 from '../../data/layouts/layout_8.json';
import layout9 from '../../data/layouts/layout_9.json';
import layout10 from '../../data/layouts/layout_10.json';
import layout11 from '../../data/layouts/layout_11.json';
import layout12 from '../../data/layouts/layout_12.json';
import layout16 from '../../data/layouts/layout_16.json';

const layouts = {
    1: layout1,
    2: layout2,
    3: layout3,
    4: layout4,
    5: layout5,
    6: layout6,
    7: layout7,
    8: layout8,
    9: layout9,
    10: layout10,
    11: layout11,
    12: layout12,
    16: layout16,
};

export const getLayoutsByCategory = () => {
    const categories = {};
    Object.entries(layouts).forEach(([category, layoutData]) => {
        categories[category] = Object.keys(layoutData).map(id => parseInt(id));
    });
    return categories;
};

export const getLayoutData = (grid_id) => {
    const category = Math.floor(grid_id / 1000);
    return layouts[category][grid_id];
};

export const getCategoryFromGridId = (grid_id) => {
    return Math.floor(grid_id / 1000);
};

export const getCategoryName = (category) => {
    const names = {
        1: "1 Image",
        2: "2 Images",
        3: "3 Images",
        4: "4 Images",
        5: "5 Images",
        6: "6 Images",
        7: "7 Images",
        8: "8 Images",
        9: "9 Images",
        10: "10 Images",
        11: "11 Images",
        12: "12 Images",
        16: "16 Images"
    };
    return names[category] || `${category} Images`;
};