import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import { PhotoAlbum as PhotoAlbumIcon, Add as AddIcon } from '@mui/icons-material';
import AlbumList from './AlbumList';
import AlbumEdit from './AlbumEdit';
import { getAlbumPages, getSelectedImages, createOrUpdateAlbumPage } from '../../services/projectApi';

const Album = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [pages, setPages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const [pagesResponse, imagesResponse] = await Promise.all([
                getAlbumPages(projectId),
                getSelectedImages(projectId)
            ]);

            setPages(pagesResponse.pages);
            setSelectedImages(imagesResponse.images);
        } catch (err) {
            console.error('Error fetching album data:', err);
            setError('Failed to load album data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [projectId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getImagePlacementMap = useCallback(() => {
        const placementMap = new Map();
        pages.forEach((page, pageIndex) => {
            page.image_props.forEach(prop => {
                if (!placementMap.has(prop.image_id)) {
                    placementMap.set(prop.image_id, []);
                }
                placementMap.get(prop.image_id).push(pageIndex + 1);
            });
        });
        return placementMap;
    }, [pages]);

    const handleAddPage = async () => {
        const newPageNumber = pages.length + 1;
        const newPage = {
            page_number: newPageNumber,
            grid_id: 1001,
            image_props: []
        };

        try {
            const result = await createOrUpdateAlbumPage(projectId, newPage);
            const updatedPage = { ...newPage, page_id: result.page_id };
            setPages(prevPages => [...prevPages, updatedPage]);
            navigate(`/project/album/${projectId}/edit/${newPageNumber}`);
        } catch (error) {
            console.error('Error adding new page:', error);
            setError('Failed to add new page. Please try again.');
        }
    };

    const renderEmptyState = () => (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="50vh"
        >
            <PhotoAlbumIcon sx={{ fontSize: 100, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h5" color="text.secondary" gutterBottom>
                Your album is empty
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center" maxWidth="400px" mb={3}>
                Start creating your album by adding pages. You can then arrange your selected images into beautiful layouts.
            </Typography>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddPage}
            >
                Add First Page
            </Button>
        </Box>
    );

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Routes>
            <Route index element={
                pages.length === 0 ? renderEmptyState() : (
                    <AlbumList
                        pages={pages}
                        setPages={setPages}
                        selectedImages={selectedImages}
                        onAddPage={handleAddPage}
                    />
                )
            } />
            <Route
                path="edit/:pageNumber"
                element={
                    <AlbumEdit
                        pages={pages}
                        setPages={setPages}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                        imagePlacementMap={getImagePlacementMap()}
                    />
                }
            />
        </Routes>
    );
};

export default Album;